import React, { useLayoutEffect } from "react";
// react component used to create charts
import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map
import { VectorMap } from "react-jvectormap";
import ReactDatetime from "react-datetime";
import moments from "moment-timezone";
import am5index from "@amcharts/amcharts5/index";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ReactPaginate from "react-paginate";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  OverlayTrigger,
  Table,
  Tooltip,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import {
  getTotalOrderByStatus,
  getDetailOrderByStatus,
  getTotalPl,
  getTotalPo,
  getTotalOmset,
  getGrafikOmset,
  getTotalPlProgres,
  getTotalPoProgres,
  getGrafikStok,
  getGrafikTopProduk,
} from "stores";
import { useDispatch, useSelector } from "react-redux";
import "./Pagination.css";

function Dashboard() {
  const dispatch = useDispatch();
  const dashbordStore = useSelector((state) => state.dashboardReducer);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [detailStatus, setDetailStatus] = React.useState([]);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [tglAwalGrafik, setTglAwalGrafik] = React.useState(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const [tglAkhirGrafik, setTglAkhirGrafik] = React.useState(
    new Date(new Date().getFullYear(), 11, 31).setHours(0, 0, 0, 0)
  );
  const [page, setPage] = React.useState(0);
  const [pageTop, setPageTop] = React.useState(0);
  const [kategoriDetail, setKategoriDetail] = React.useState("");
  React.useEffect(() => {
    getGrafikOmset(dispatch, {
      tglAwal: moments
        .tz(new Date(tglAwalGrafik), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhirGrafik), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    });
  }, [tglAwalGrafik, tglAkhirGrafik]);

  React.useEffect(() => {
    getGrafikTopProduk(dispatch, {
      page: pageTop + 1,
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    });
  }, [tglAwal, tglAkhir, pageTop]);

  React.useEffect(() => {
    getGrafikStok(dispatch, {
      page: page + 1,
    });
  }, [page]);

  React.useEffect(() => {
    getTotalOrderByStatus(dispatch, {
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    });
    getTotalOmset(dispatch, {
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    });
    getTotalPl(dispatch);
    getTotalPo(dispatch);
    getTotalPlProgres(dispatch);
    getTotalPoProgres(dispatch);
  }, [tglAwal, tglAkhir]);

  React.useEffect(() => {
    var root = am5.Root.new("chartdiv");
    root.setThemes([am5themes_Animated.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 5,
      fontSize: 12,
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "bulan",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
    });

    yRenderer.labels.template.setAll({
      fontSize: 12,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
        min: 0,
      })
    );
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "totalNominal",
        sequencedInterpolation: true,
        categoryXField: "bulan",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });
    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    let data = dashbordStore.grafikOmset;

    xAxis.data.setAll(data);
    series.data.setAll(data);
    return () => {
      root.dispose();
    };
  }, [dashbordStore.grafikOmset]);

  React.useEffect(() => {
    var root = am5.Root.new("chartdiv2");
    root.setThemes([am5themes_Animated.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );
    // let data = [
    //   {
    //     year: "2021",
    //     europe: 2.5,
    //     namerica: 2.5,
    //   },
    //   {
    //     year: "2022",
    //     europe: 2.6,
    //     namerica: 2.7,
    //   },
    //   {
    //     year: "2023",
    //     europe: 2.8,
    //     namerica: 2.9,
    //   },
    // ];
    let data = !dashbordStore.grafikStok.data
      ? []
      : dashbordStore.grafikStok.data;

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
      minorGridEnabled: true,
    });
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "kodeProduk",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    xRenderer.grid.template.setAll({
      location: 1,
    });
    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      fontSize: 10,
      paddingRight: 5,
    });
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
        min: 0,
        extraMinMaxPrecision: false,
      })
    );

    // yAxis.get("renderer").labels.template.setAll({
    //   text: "{value.formatNumber('#')}",
    //   fontSize: 12, // Ganti ukuran sesuai keinginan Anda
    // });

    function makeSeries(name, fieldName, color) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "kodeProduk",
        })
      );
      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}:{valueY}",
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
        fill: am5.color(color), // Menentukan warna fill bar
        stroke: am5.color(color), // Menentukan warna stroke bar
      });
      series.data.setAll(data);

      series.appear();
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });
      legend.data.push(series);
    }
    makeSeries("Stok Gudang", "jumlahStock", "#4579F0");
    makeSeries("Minimal Stok", "minimalStock", "#FB404B");
    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [dashbordStore.grafikStok]);

  React.useEffect(() => {
    var root = am5.Root.new("chartdiv3");
    root.setThemes([am5themes_Animated.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );
    // let data = [
    //   {
    //     year: "2021",
    //     europe: 2.5,
    //     namerica: 2.5,
    //   },
    //   {
    //     year: "2022",
    //     europe: 2.6,
    //     namerica: 2.7,
    //   },
    //   {
    //     year: "2023",
    //     europe: 2.8,
    //     namerica: 2.9,
    //   },
    // ];
    let data = !dashbordStore.grafikTopProduk.data
      ? []
      : dashbordStore.grafikTopProduk.data;

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
      minorGridEnabled: true,
    });
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "kodeProduk",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    xRenderer.grid.template.setAll({
      location: 1,
    });
    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      fontSize: 10,
      paddingRight: 5,
    });
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
        min: 0,
        extraMinMaxPrecision: false,
      })
    );

    // yAxis.get("renderer").labels.template.setAll({
    //   text: "{value.formatNumber('#')}",
    //   fontSize: 12, // Ganti ukuran sesuai keinginan Anda
    // });

    function makeSeries(name, fieldName, color) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "kodeProduk",
        })
      );
      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}:{valueY}",
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
        fill: am5.color(color), // Menentukan warna fill bar
        stroke: am5.color(color), // Menentukan warna stroke bar
      });
      series.data.setAll(data);

      series.appear();
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });
      legend.data.push(series);
    }
    makeSeries("Terjual", "totalOrders", "#4579F0");

    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [dashbordStore.grafikTopProduk]);
  return (
    <>
      <Container fluid>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <div>
              <img
                style={{ width: "600px", height: "auto" }}
                src={require("assets/img/LOGONEW1.png").default}
                alt="react-logo"
              />
            </div>
          </Col>
          <Col>
            <Row>
              <Col>
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Tanggal Awal
                </div>
                <Form.Group
                  style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                >
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      readOnly: true,
                      style: {
                        cursor: "default",
                        color: "black",
                        backgroundColor: "white",
                      },
                    }}
                    onChange={(e) => {
                      e.toDate().setHours(0, 0, 0, 0),
                        setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                    }}
                    value={tglAwal}
                    initialValue={tglAwal}
                    timeFormat={false}
                    dateFormat="DD/MM/YYYY"
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col>
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Tanggal Akhir
                </div>
                <Form.Group
                  style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                >
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      readOnly: true,
                      style: {
                        cursor: "default",
                        color: "black",
                        backgroundColor: "white",
                      },
                    }}
                    onChange={(e) => {
                      e.toDate().setHours(23, 59, 0, 0),
                        setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                    }}
                    value={tglAkhir}
                    initialValue={tglAkhir}
                    timeFormat={false}
                    dateFormat="DD/MM/YYYY"
                  ></ReactDatetime>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
            <Card
              className="card-stats"
              style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            >
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-people-carry text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Pesan Langsung</p>
                      <p
                        style={{
                          fontSize: 12,
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        Deadline
                      </p>
                      <Card.Title
                        as="h4"
                        style={{ color: "red", fontWeight: "bold" }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalDetail(true);
                            setKategoriDetail("SO");
                            getTotalPl(dispatch).then((respon) => {
                              setDetailStatus(respon.data.data2);
                            });
                          }}
                        >
                          {dashbordStore.totalPl && dashbordStore.totalPl}{" "}
                          Pesanan{" "}
                          <i
                            className="fas fa-external-link-alt mr-1"
                            style={{
                              fontSize: 10,
                              position: "absolute",
                              paddingRight: 5,
                            }}
                          ></i>
                        </div>
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <label
                    onClick={() => {
                      setModalDetail(true);
                      setKategoriDetail("SO");
                      getTotalPlProgres(dispatch).then((respon) => {
                        setDetailStatus(respon.data.data2);
                      });
                    }}
                    style={{
                      textTransform: "none",
                      fontSize: 14,
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Progres :{" "}
                    {dashbordStore.totalPlProgres &&
                      dashbordStore.totalPlProgres}{" "}
                    Pesanan{" "}
                    <i
                      className="fas fa-external-link-alt mr-1"
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        paddingRight: 5,
                      }}
                    ></i>
                  </label>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card
              className="card-stats"
              style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            >
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fas fa-store text-default"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Purchase Order</p>
                      <p
                        style={{
                          fontSize: 12,
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        Deadline
                      </p>
                      <Card.Title
                        as="h4"
                        style={{ color: "red", fontWeight: "bold" }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalDetail(true);
                            setKategoriDetail("PO");
                            getTotalPo(dispatch).then((respon) => {
                              setDetailStatus(respon.data.data2);
                            });
                          }}
                        >
                          {dashbordStore.totalPo && dashbordStore.totalPo} PO{" "}
                          <i
                            className="fas fa-external-link-alt mr-1"
                            style={{
                              fontSize: 10,
                              position: "absolute",
                              paddingRight: 5,
                            }}
                          ></i>
                        </div>
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <div className="stats">
                    <label
                      onClick={() => {
                        setModalDetail(true);
                        setKategoriDetail("PO");
                        getTotalPoProgres(dispatch).then((respon) => {
                          setDetailStatus(respon.data.data2);
                        });
                      }}
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      Progres :{" "}
                      {dashbordStore.totalPoProgres &&
                        dashbordStore.totalPoProgres}{" "}
                      Purchase Order{" "}
                      <i
                        className="fas fa-external-link-alt mr-1"
                        style={{
                          fontSize: 10,
                          position: "absolute",
                          paddingRight: 5,
                        }}
                      ></i>
                    </label>
                  </div>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="6" sm="6">
            <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
              {/* <Card.Header style={{ fontWeight: "bold", color: "grey" }}>
                Omzet
              </Card.Header> */}
              <Card.Body style={{ paddingTop: 0 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold", color: "grey" }}>Omzet</div>
                  <div style={{ fontSize: 40, fontWeight: "bold" }}>
                    {dashbordStore.totalOmset &&
                      new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(dashbordStore.totalOmset)}
                  </div>
                </div>
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => {
                        setModalDetail(true);
                        setDetailStatus(
                          dashbordStore.totalOmset && dashbordStore.listDp
                        );
                      }}
                    >
                      DP{" "}
                      <i
                        className="fas fa-external-link-alt mr-1"
                        style={{ fontSize: 10, position: "absolute" }}
                      ></i>
                    </div>
                    <div>
                      {/* {dashbordStore.totalOmset &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(dashbordStore.totalDp)} */}
                      {dashbordStore.totalOmset && dashbordStore.totalDp} SO
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => {
                        setModalDetail(true);
                        setDetailStatus(
                          dashbordStore.totalOmset && dashbordStore.listLunas
                        );
                      }}
                    >
                      Lunas{" "}
                      <i
                        className="fas fa-external-link-alt mr-1"
                        style={{ fontSize: 10, position: "absolute" }}
                      ></i>
                    </div>
                    <div>
                      {/* {dashbordStore.totalOmset &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(dashbordStore.totalLunas)} */}
                      {dashbordStore.totalOmset && dashbordStore.totalLunas} SO
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => {
                        setModalDetail(true);
                        setDetailStatus(
                          dashbordStore.totalOmset &&
                            dashbordStore.listBelumBayar
                        );
                      }}
                    >
                      Belum Dibayar{" "}
                      <i
                        className="fas fa-external-link-alt mr-1"
                        style={{ fontSize: 10, position: "absolute" }}
                      ></i>
                    </div>
                    <div>
                      {/* {dashbordStore.totalOmset &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(
                          dashbordStore.totalOmset -
                            dashbordStore.totalDp -
                            dashbordStore.totalLunas
                        )} */}
                      {dashbordStore.totalOmset &&
                        dashbordStore.totalBelumBayar}{" "}
                      SO
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card
          style={{ padding: 20, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <h3>
            <b>Status Order</b>
          </h3>
          <Row style={{ marginTop: 15 }}>
            <Col lg="3" sm="6">
              <Card
                className="card-stats"
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-list-ol text-danger"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Order</p>
                        <Card.Title as="h4">
                          {dashbordStore.allOrderStatus &&
                            dashbordStore.allOrderStatus.data.ajukan +
                              dashbordStore.allOrderStatus.data.proses +
                              dashbordStore.allOrderStatus.data.belum +
                              dashbordStore.allOrderStatus.data.pending +
                              dashbordStore.allOrderStatus.data.kirim +
                              dashbordStore.allOrderStatus.data.cancel}{" "}
                          Order
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div
                    className="stats"
                    style={{
                      justifyContent: "end",
                      alignItems: "end",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        setModalDetail(true);
                        setKategoriDetail("SO");
                        getDetailOrderByStatus(dispatch, {
                          tglAwal: moments
                            .tz(new Date(tglAwal), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          tglAkhir: moments
                            .tz(new Date(tglAkhir), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          statusOrder: "",
                        }).then((respon) => {
                          setDetailStatus(respon.data.data);
                        });
                      }}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      <i className="fas fa-external-link-alt mr-1"></i>
                      Detail
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card
                className="card-stats"
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-clipboard-list text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Pengajuan</p>
                        <Card.Title as="h4">
                          {dashbordStore.allOrderStatus &&
                            dashbordStore.allOrderStatus.data.ajukan}{" "}
                          Order
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div
                    className="stats"
                    style={{
                      justifyContent: "end",
                      alignItems: "end",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        setModalDetail(true);
                        setKategoriDetail("SO");
                        getDetailOrderByStatus(dispatch, {
                          tglAwal: moments
                            .tz(new Date(tglAwal), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          tglAkhir: moments
                            .tz(new Date(tglAkhir), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          statusOrder: "Ajukan",
                        }).then((respon) => {
                          setDetailStatus(respon.data.data);
                        });
                      }}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      <i className="fas fa-external-link-alt mr-1"></i>
                      Detail
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card
                className="card-stats"
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-cogs text-success"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Diproses</p>
                        <Card.Title as="h4">
                          {" "}
                          {dashbordStore.allOrderStatus &&
                            dashbordStore.allOrderStatus.data.proses}{" "}
                          Order
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div
                    className="stats"
                    style={{
                      justifyContent: "end",
                      alignItems: "end",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        setModalDetail(true);
                        setKategoriDetail("SO");
                        getDetailOrderByStatus(dispatch, {
                          tglAwal: moments
                            .tz(new Date(tglAwal), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          tglAkhir: moments
                            .tz(new Date(tglAkhir), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          statusOrder: "Proses",
                        }).then((respon) => {
                          setDetailStatus(respon.data.data);
                        });
                      }}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      <i className="fas fa-external-link-alt mr-1"></i>
                      Details
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card
                className="card-stats"
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-truck-loading text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Belum Kirim</p>
                        <Card.Title as="h4">
                          {" "}
                          {dashbordStore.allOrderStatus &&
                            dashbordStore.allOrderStatus.data.belum}{" "}
                          Order
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div
                    className="stats"
                    style={{
                      justifyContent: "end",
                      alignItems: "end",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        setModalDetail(true);
                        setKategoriDetail("SO");
                        getDetailOrderByStatus(dispatch, {
                          tglAwal: moments
                            .tz(new Date(tglAwal), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          tglAkhir: moments
                            .tz(new Date(tglAkhir), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          statusOrder: "Belum",
                        }).then((respon) => {
                          setDetailStatus(respon.data.data);
                        });
                      }}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      <i className="fas fa-external-link-alt mr-1"></i>
                      Details
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card
                className="card-stats"
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-clock text-primary"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Pending</p>
                        <Card.Title as="h4">
                          {" "}
                          {dashbordStore.allOrderStatus &&
                            dashbordStore.allOrderStatus.data.pending}{" "}
                          Order
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div
                    className="stats"
                    style={{
                      justifyContent: "end",
                      alignItems: "end",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        setModalDetail(true);
                        setKategoriDetail("SO");
                        getDetailOrderByStatus(dispatch, {
                          tglAwal: moments
                            .tz(new Date(tglAwal), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          tglAkhir: moments
                            .tz(new Date(tglAkhir), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          statusOrder: "Pending",
                        }).then((respon) => {
                          setDetailStatus(respon.data.data);
                        });
                      }}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      <i className="fas fa-external-link-alt mr-1"></i>
                      Details
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card
                className="card-stats"
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-truck text-success"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Dikirim</p>
                        <Card.Title as="h4">
                          {" "}
                          {dashbordStore.allOrderStatus &&
                            dashbordStore.allOrderStatus.data.kirim}{" "}
                          Order
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div
                    className="stats"
                    style={{
                      justifyContent: "end",
                      alignItems: "end",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        setModalDetail(true);
                        setKategoriDetail("SO");
                        getDetailOrderByStatus(dispatch, {
                          tglAwal: moments
                            .tz(new Date(tglAwal), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          tglAkhir: moments
                            .tz(new Date(tglAkhir), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          statusOrder: "Kirim",
                        }).then((respon) => {
                          setDetailStatus(respon.data.data);
                        });
                      }}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      <i className="fas fa-external-link-alt mr-1"></i>
                      Details
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card
                className="card-stats"
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-truck-loading text-danger"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Cancel</p>
                        <Card.Title as="h4">
                          {" "}
                          {dashbordStore.allOrderStatus &&
                            dashbordStore.allOrderStatus.data.cancel}{" "}
                          Order
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div
                    className="stats"
                    style={{
                      justifyContent: "end",
                      alignItems: "end",
                      display: "flex",
                    }}
                  >
                    <div
                      onClick={() => {
                        setModalDetail(true);
                        setKategoriDetail("SO");
                        getDetailOrderByStatus(dispatch, {
                          tglAwal: moments
                            .tz(new Date(tglAwal), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          tglAkhir: moments
                            .tz(new Date(tglAkhir), "Asia/Jakarta")
                            .format("YYYY-MM-DD"),
                          statusOrder: "Cancel",
                        }).then((respon) => {
                          setDetailStatus(respon.data.data);
                        });
                      }}
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      <i className="fas fa-external-link-alt mr-1"></i>
                      Details
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Card>

        {/* <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Global Sales by Top Locations</Card.Title>
                <p className="card-category">All products that were shipped</p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="6">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/US.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>USA</td>
                          <td className="text-right">2.920</td>
                          <td className="text-right">53.23%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/DE.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>Germany</td>
                          <td className="text-right">1.300</td>
                          <td className="text-right">20.43%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/AU.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>Australia</td>
                          <td className="text-right">760</td>
                          <td className="text-right">10.35%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/GB.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>United Kingdom</td>
                          <td className="text-right">690</td>
                          <td className="text-right">7.87%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/RO.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>Romania</td>
                          <td className="text-right">600</td>
                          <td className="text-right">5.94%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/BR.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>Brasil</td>
                          <td className="text-right">550</td>
                          <td className="text-right">4.34%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col className="ml-auto mr-auto" md="6">
                    <VectorMap
                      map={"world_mill"}
                      backgroundColor="transparent"
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "300px",
                      }}
                      containerClassName="map"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0,
                        },
                      }}
                      series={{
                        regions: [
                          {
                            values: {
                              AU: 760,
                              BR: 550,
                              CA: 120,
                              DE: 1300,
                              FR: 540,
                              GB: 690,
                              GE: 200,
                              IN: 200,
                              RO: 600,
                              RU: 300,
                              US: 2920,
                            },
                            scale: ["#AAAAAA", "#444444"],
                            normalizeFunction: "polynomial",
                          },
                        ],
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Email Statistics</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  className="ct-perfect-fourth"
                  data={{
                    labels: ["40%", "20%", "40%"],
                    series: [40, 20, 40],
                  }}
                  type="Pie"
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Open <i className="fas fa-circle mr-1 text-danger"></i>
                  Bounce <i className="fas fa-circle mr-1 text-warning"></i>
                  Unsubscribe
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Users Behavior</Card.Title>
                <p className="card-category">24 Hours performance</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  data={{
                    labels: [
                      "9:00AM",
                      "12:00AM",
                      "3:00PM",
                      "6:00PM",
                      "9:00PM",
                      "12:00PM",
                      "3:00AM",
                      "6:00AM",
                      "",
                    ],
                    series: [
                      [287, 385, 490, 492, 554, 586, 698, 695, 630],
                      [67, 152, 143, 240, 287, 335, 435, 437, 470],
                      [23, 113, 67, 108, 190, 239, 307, 308, 430],
                    ],
                  }}
                  type="Line"
                  options={{
                    low: 0,
                    high: 800,
                    showArea: false,
                    height: "245px",
                    axisX: {
                      showGrid: false,
                    },
                    lineSmooth: true,
                    showLine: true,
                    showPoint: true,
                    fullWidth: true,
                    chartPadding: {
                      right: 50,
                    },
                  }}
                  responsiveOptions={[
                    [
                      "screen and (max-width: 640px)",
                      {
                        axisX: {
                          labelInterpolationFnc: function (value) {
                            return value[0];
                          },
                        },
                      },
                    ],
                  ]}
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Open <i className="fas fa-circle mr-1 text-danger"></i>
                  Click <i className="fas fa-circle mr-1 text-warning"></i>
                  Click Second Time
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-history"></i>
                  Updated 3 minutes ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">2017 Sales</Card.Title>
                <p className="card-category">All products including Taxes</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  data={{
                    labels: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "Mai",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ],
                    series: [
                      [
                        542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756,
                        895,
                      ],
                      [
                        412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636,
                        695,
                      ],
                    ],
                  }}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                  }}
                  responsiveOptions={[
                    [
                      "screen and (max-width: 640px)",
                      {
                        seriesBarDistance: 5,
                        axisX: {
                          labelInterpolationFnc: function (value) {
                            return value[0];
                          },
                        },
                      },
                    ],
                  ]}
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Tesla Model S{" "}
                  <i className="fas fa-circle mr-1 text-danger"></i>
                  BMW 5 Series
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-check"></i>
                  Data information certified
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as="h4">Tasks</Card.Title>
                <p className="card-category">Backend development</p>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          Sign contract for "What are conference organizers
                          afraid of?"
                        </td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-688296980">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-202192706">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          Lines From Great Russian Literature? Or E-mails From
                          My Boss?
                        </td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-746544352">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-743037005">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          Flooded: One year later, assessing what was lost and
                          what was found when a ravaging rain swept through
                          metro Detroit
                        </td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-855684210">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-242945902">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultChecked
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          Create 4 Invisible User Experiences you Never Knew
                          About
                        </td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-488557184">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-789597281">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>Read "Following makes Medium better"</td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-521344137">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-934093947">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>Unfollow 5 enemies from twitter</td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-97404283">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-888894273">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="now-ui-icons loader_refresh spin"></i>
                  Updated 3 minutes ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row> */}
        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
          <Card.Header>
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>
                Grafik Omzet tiap Bulan
              </div>
              <div
                style={{
                  marginLeft: 15,
                }}
              >
                <Form.Group
                  style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                >
                  <ReactDatetime
                    dateFormat="YYYY"
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      readOnly: true,
                      style: {
                        cursor: "default",
                        color: "black",
                        backgroundColor: "white",
                        width: 100,
                      },
                    }}
                    onChange={(e) => {
                      let year = moments
                        .tz(new Date(e.toDate()), "Asia/Jakarta")
                        .format("YYYY");
                      setTglAwalGrafik(
                        new Date(year, 0, 1).setHours(0, 0, 0, 0)
                      );

                      setTglAkhirGrafik(
                        new Date(year, 11, 31).setHours(0, 0, 0, 0)
                      );
                    }}
                    value={tglAwalGrafik}
                    initialValue={tglAwalGrafik}
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div id="chartdiv" style={{ width: "100%", height: 500 }}></div>
          </Card.Body>
        </Card>
        {/* <Card
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            width: "100%",
          }}
        >
          <Card.Header>
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>
                Grafik Stok Barang
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div style={{ overflowX: "auto" }}>
              <div id="chartdiv2" style={{ width: 5000, height: 400 }}></div>
            </div>
          </Card.Body>
        </Card> */}
        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
          <Card.Header>
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>
                Grafik Stok Barang
              </div>
              <div
                style={{
                  marginLeft: 15,
                }}
              >
                <Form.Group
                // style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                >
                  <ReactPaginate
                    forcePage={page}
                    containerClassName="paginations justify-content-end"
                    previousClassName="pages-items-containers"
                    previousLinkClassName="pages-links-labels"
                    nextClassName="pages-items-containers"
                    nextLinkClassName="pages-links-labels"
                    breakClassName="pages-items-containers"
                    pageCount={dashbordStore.grafikStok.totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageLinkClassName="pages-links-labels"
                    pageClassName="pages-items-containers"
                    activeClassName="actives"
                    activeLinkClassName="activess"
                    nextLabel=" > "
                    previousLabel=" < "
                    initialPage={page}
                    disableInitialCallback={true}
                    onPageChange={(val) => {
                      setPage(val.selected);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div id="chartdiv2" style={{ width: "100%", height: 500 }}></div>
          </Card.Body>
        </Card>

        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
          <Card.Header>
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>
                Grafik Top Produk
              </div>
              <div
                style={{
                  marginLeft: 15,
                }}
              >
                <Form.Group
                // style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                >
                  <ReactPaginate
                    forcePage={pageTop}
                    containerClassName="paginations justify-content-end"
                    previousClassName="pages-items-containers"
                    previousLinkClassName="pages-links-labels"
                    nextClassName="pages-items-containers"
                    nextLinkClassName="pages-links-labels"
                    breakClassName="pages-items-containers"
                    pageCount={dashbordStore.grafikTopProduk.totalPages}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageLinkClassName="pages-links-labels"
                    pageClassName="pages-items-containers"
                    activeClassName="actives"
                    activeLinkClassName="activess"
                    nextLabel=" > "
                    previousLabel=" < "
                    initialPage={pageTop}
                    disableInitialCallback={true}
                    onPageChange={(val) => {
                      setPageTop(val.selected);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div id="chartdiv3" style={{ width: "100%", height: 500 }}></div>
          </Card.Body>
        </Card>
      </Container>

      <Modal
        size={kategoriDetail === "PO" ? "sm" : "md"}
        show={modalDetail}
        onHide={() => {
          setModalDetail(false);
          setDetailStatus([]);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                {kategoriDetail === "PO" ? "List PO" : "List SO"}

                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
              {detailStatus.length === 0 ? (
                <span style={{ fontSize: 16 }}>Tidak ada data</span>
              ) : (
                <ol type="1">
                  {detailStatus.map((val) => {
                    console.log(val);
                    return (
                      <li style={{ marginBottom: 5 }}>
                        <span style={{ fontSize: 16 }}>
                          {kategoriDetail === "PO"
                            ? val.idPo
                            : `${val.idSo}-${val.namaCustomer}`}
                        </span>
                      </li>
                    );
                  })}
                </ol>
              )}
            </Card.Body>
            {/* <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={addPo}
              >
                Submit
              </Button>
            </Card.Footer> */}
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default Dashboard;
