import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { getInvSj, tambahSj } from "stores";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";
import NotificationAlert from "react-notification-alert";
import { editStatusSj } from "stores";
import { cetakSj } from "stores";

function SuratJalan() {
  const dispatch = useDispatch();
  const orderSj = useSelector((state) => state.suratJalanReducer);
  const [listOrder, setListOrder] = React.useState([]);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const notificationAlertRef = React.useRef(null);

  const [modal, setModal] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(false);
  const [detailOrder, setDetailOrder] = React.useState(null);
  const [pending, setPending] = React.useState(false);

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  const [modalAddSj, setModalAddSj] = React.useState(false);
  const [modalEditSj, setModalEditSj] = React.useState(false);
  const [nomorResi, setNomorResi] = React.useState("");
  const [keterangan, setKeterangan] = React.useState("");
  const [namaEkspedisi, setNamaEkspedisi] = React.useState("");
  const [statusBarang, setStatusBarang] = React.useState("");
  const [idSo, setIdSo] = React.useState("");
  const [idSj, setIdSj] = React.useState("");
  const [idInvoice, setIdInvoice] = React.useState("");
  const [urlImage, setUrlImage] = React.useState("");
  const [dokumen, setDokumen] = React.useState([]);

  const [nomorResiValidasi, setNomorResiValidasi] = React.useState(false);
  const [namaEkspedisiValidasi, setNamaEkspedisiValidasi] =
    React.useState(false);
  const [keteranganValidasi, setKeteranganValidasi] = React.useState(false);
  const [statusBarangValidasi, setStatusBarangValidasi] = React.useState(false);
  const [dokumenValidasi, setDokumenValidasi] = React.useState(false);

  const [alamatPenerima, setAlamatPenerima] = React.useState("");
  const [namaPenerima, setNamaPenerima] = React.useState("");
  const [noHpPenerima, setNoHpPenerima] = React.useState("");
  const [alamatEkspedisi, setAlamatEkspedisi] = React.useState("");
  const [telpEkspedisi, setTelpEkspedisi] = React.useState("");

  function handleFileChange(e) {
    // const selectedFiles = Array.from(e.target.files);
    setDokumen([...dokumen, ...Array.from(e.target.files)]);
    setDokumenValidasi(false);
  }

  const removeDokumen = (index) => {
    setDokumen(dokumen.filter((_, i) => i !== index));
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        marginTop: 5,
        marginBottom: 5,
        // height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };
  const cetakSjPdf = (data) => {
    cetakSj({ idSo: data.idSo }).then((response) => {
      if (response.status === 200) {
        const byteCharacters = atob(response.data.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        const newTab = window.open(blobUrl, "_blank");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan Produk"
            : response.data.message,
        });
      }
    });
  };
  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + orderSj.listInvSj.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setDetailOrder(row);
                  setModal(true);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              <div
                onClick={() => {
                  if (row.suratJalan.length === 0) {
                    null;
                  } else {
                    setModalEditSj(true);
                    setIdSj(row.suratJalan[0].idSuratJalan);
                    setIdSo(row.idSo);
                    setNamaPenerima(row.namaPenerima);
                    setAlamatPenerima(row.alamatPenerima);
                    setNoHpPenerima(row.noTelpPenerima);
                    setNamaEkspedisi(row.suratJalan[0].namaEkspedisi);
                    setAlamatEkspedisi(row.suratJalan[0].alamatEkspedisi);
                    setTelpEkspedisi(row.suratJalan[0].telpEkspedisi);
                    setNomorResi(row.suratJalan[0].nomorResi);
                    setKeterangan(row.suratJalan[0].keterangan);
                  }
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: row.suratJalan.length === 0 ? "unset" : "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Edit </Tooltip>}
                >
                  <i
                    style={{
                      color: row.suratJalan.length === 0 ? "grey" : "#478964",
                      fontSize: 16,
                    }}
                    className="fas fa-edit"
                  ></i>
                </OverlayTrigger>
              </div>

              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
              <div
                onClick={() => {
                  if (row.statusSo === "Cancel") {
                    null;
                  } else {
                    row.suratJalan.length === 0 ? null : cetakSjPdf(row);
                  }
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor:
                    row.suratJalan.length === 0 || row.statusSo === "Cancel"
                      ? "unset"
                      : "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Cetak PDF</Tooltip>}
                >
                  <i
                    style={{
                      color:
                        row.suratJalan.length === 0 || row.statusSo === "Cancel"
                          ? "grey"
                          : "#FF0000",
                      fontSize: 16,
                    }}
                    className="fas fa-file-pdf"
                  ></i>
                </OverlayTrigger>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Surat Jalan",
      width: "200px",
      center: true,
      selector: (row) => (
        // rgb(68,125,247)
        <Button
          onClick={() => {
            if (row.statusSo === "Cancel") {
              null;
            } else {
              setModalAddSj(true);
              setIdSo(row.idSo);
              setIdInvoice(row.invoices[row.invoices.length - 1].idInvoice);

              setNamaPenerima(row.namaPenerima);
              setAlamatPenerima(row.alamatPenerima);
              setNoHpPenerima(row.noTelpPenerima);
            }
          }}
          disabled={
            row.suratJalan.length === 0 &&
            row.totalOrders.Progress === 0 &&
            row.statusSo != "Cancel"
              ? false
              : true
          }
          style={{
            backgroundColor:
              row.suratJalan.length === 0 &&
              row.totalOrders.Progress === 0 &&
              row.statusSo != "Cancel"
                ? "rgb(68,125,247)"
                : "rgba(128,128,128,0.3)",
            borderColor:
              row.suratJalan.length === 0 &&
              row.totalOrders.Progress === 0 &&
              row.statusSo != "Cancel"
                ? "rgb(68,125,247)"
                : "rgba(128,128,128,0.3)",
            color:
              row.suratJalan.length === 0 &&
              row.totalOrders.Progress === 0 &&
              row.statusSo != "Cancel"
                ? "rgb(255,255,255)"
                : "rgba(128,128,128,1)",
            cursor:
              row.suratJalan.length === 0 &&
              row.totalOrders.Progress === 0 &&
              row.statusSo != "Cancel"
                ? "pointer"
                : "unset",
          }}
        >
          + Surat Jalan
        </Button>
      ),
    },
    {
      name: "Kode Surat Jalan",
      width: "200px",
      center: true,
      selector: (row) =>
        row.suratJalan.length === 0 ? "-" : row.suratJalan[0].idSuratJalan,
    },
    {
      name: "Kode Order",
      width: "200px",
      center: true,
      selector: (row) => row.idSo,
    },
    {
      name: "Status Mesin Progress",
      width: "200px",
      center: true,
      selector: (row) => row.totalOrders.Progress,
    },
    {
      name: "Status Mesin Selesai",
      width: "200px",
      center: true,
      selector: (row) => row.totalOrders.Selesai,
    },
    {
      name: "Status Order",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusSo === "Ajukan" ? (
          <div
            style={{
              backgroundColor: "rgba(255,163,47,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(255,163,47)",
            }}
          >
            <b style={{ color: "rgb(255,163,47)" }}>Diorder</b>
          </div>
        ) : row.statusSo === "Proses" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,239)",
            }}
          >
            <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
          </div>
        ) : row.statusSo === "Tolak" ? (
          <div
            style={{
              backgroundColor: "rgba(251,59,70,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(251,59,70)",
            }}
          >
            <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
          </div>
        ) : row.statusSo === "Kirim" ? (
          <div
            style={{
              backgroundColor: "rgba(68,125,247,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(68,125,247)",
            }}
          >
            <b style={{ color: "rgb(68,125,247)" }}>Dikirim</b>
          </div>
        ) : row.statusSo === "Belum" ? (
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(0,0,0)",
            }}
          >
            <b style={{ color: "rgb(0,0,0)" }}>Belum Kirim</b>
          </div>
        ) : row.statusSo === "Pending" ? (
          <div
            style={{
              backgroundColor: "rgba(247, 0, 239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(247, 0, 239)",
            }}
          >
            <b style={{ color: "rgb(247, 0, 239)" }}>Pending</b>
          </div>
        ) : row.statusSo === "Diambil" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Diambil</b>
          </div>
        ) : row.statusSo === "Cancel" ? (
          <div
            style={{
              backgroundColor: "rgba(251,59,70,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(251,59,70)",
            }}
          >
            <b style={{ color: "rgb(251,59,70)" }}>Cancel</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
          </div>
        ),
    },
    {
      name: "Status Pembayaran",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusPembayaran === "" || !row.statusPembayaran ? (
          <span style={{ color: "rgb(251,59,70)" }}>
            <b>-</b>
          </span>
        ) : row.statusPembayaran === "Pending" ? (
          <div
            style={{
              backgroundColor: "rgba(255,163,47,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(255,163,47)",
            }}
          >
            <b style={{ color: "rgb(255,163,47)" }}>Pending</b>
          </div>
        ) : row.statusPembayaran === "Proses" ? (
          <div
            style={{
              backgroundColor: "rgba(68,125,247,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(68,125,247)",
            }}
          >
            <b style={{ color: "rgb(68,125,247)" }}>Proses</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Lunas</b>
          </div>
        ),
    },
    {
      name: "Status Lunas",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusLunas === false ? (
          <span style={{ color: "rgb(251,59,70)" }}>
            <b>Belum Lunas</b>
          </span>
        ) : (
          <span style={{ color: "rgb(36,204,23)" }}>
            <b>Lunas</b>
          </span>
        ),
    },

    {
      name: "Kode Invoice",
      width: "200px",
      center: true,
      selector: (row) => row.invoices[row.invoices.length - 1].idInvoice,
    },

    {
      name: "Nama Instansi",
      width: "250px",
      selector: (row) => row.customerInstansi,
    },
    {
      name: "Nama Customer",
      width: "200px",
      selector: (row) => row.namaCustomer,
    },
    {
      name: "Order",
      width: "auto",
      selector: (row) => {
        return (
          <>
            <table cellPadding="4" border="1">
              <thead>
                <tr>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Kategori</th>
                  <th>Sumber</th>
                  <th>Qty</th>
                  <th>Selesai</th>
                </tr>
              </thead>
              <tbody>
                {row.orders.map((val) => {
                  return (
                    <>
                      <tr
                        style={{
                          backgroundColor:
                            val.qty != val.qtySelesai
                              ? "rgba(255, 3, 3,0.3)"
                              : null,
                        }}
                      >
                        <td>{val.produk.kodeProduk}</td>
                        <td>{val.produk.namaProduk}</td>
                        <td>
                          {val.produk.kategoriProduk === "upgrade"
                            ? "UPGRADE"
                            : "UTAMA"}
                        </td>
                        <td>{val.sumberProduk === "PL" ? "PL" : "SG"}</td>
                        <td>{val.qty} item</td>
                        <td style={{ textAlign: "center" }}>
                          {val.qtySelesai} item
                        </td>
                      </tr>
                    </>
                  );

                  // <li style={{ marginTop: 5 }}>
                  //   <b>{val.produk.kodeProduk}</b> - {val.produk.namaProduk}
                  //   {val.produk.kategoriProduk === "upgrade" ? " - UPGRADE" : null}
                  //   {val.sumberProduk === "PL" ? (
                  //     <b>
                  //       {" "}
                  //       <i style={{ color: "blue" }}> - PL</i>
                  //     </b>
                  //   ) : null}{" "}
                  //   - <b>{val.qty} item</b>
                  // </li>
                })}
              </tbody>
            </table>
          </>
        );
      },
    },
    {
      name: "Total Harga",
      width: "200px",
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.totalPembelian);
        return formattedValue;
      },
    },
    {
      name: "Tanggal Order",
      width: "200px",
      center: true,
      selector: (row) =>
        moments.tz(new Date(row.tglOrder), "Asia/Jakarta").format("DD-MM-YYYY"),
    },
    {
      name: "Selesai Garansi",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.selesaiGaransi
          ? "-"
          : moments
              .tz(new Date(row.selesaiGaransi), "Asia/Jakarta")
              .format("DD-MM-YYYY"),
    },
    // {
    //   name: "Stock",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.jumlahStock,
    // },
    // {
    //   name: "Minimal Stock",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.minimalStock,
    // },
    // {
    //   name: "Tanggal Data Dibuat",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.createdAt), "Asia/Jakarta")
    //       .format("DD-MM-YYYY HH:mm:ss"),
    // },
    // {
    //   name: "Tanggal Update Data",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.updatedAt), "Asia/Jakarta")
    //       .format("DD-MM-YYYY HH:mm:ss"),
    // },
  ];

  const history = useHistory();
  React.useEffect(() => {
    let tmp =
      orderSj.listInvSj.data &&
      orderSj.listInvSj.data.map((val, index) => {
        return {
          ...val,
          totalOrders: {
            Progress: val.orders.filter(
              (order) => order.statusProduk === "Progress"
            ).length,
            Selesai: val.orders.filter(
              (order) => order.statusProduk === "Selesai"
            ).length,
          },
        };
      });

    setListOrder(tmp);

    if (orderSj.listInvSj.length != 0) {
      setPending(false);
    }
  }, [orderSj.listInvSj]);

  React.useEffect(() => {
    if (searchText != "") {
      setPage(0);
    }
    getInvSj(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    });
  }, [page, totalPerHalaman, searchText, tglAwal, tglAkhir]);

  const buatSuratJalan = (e) => {
    e.preventDefault();

    // if (nomorResi === "") {
    //   setNomorResiValidasi(true);
    // }
    // if (keterangan === "") {
    //   setKeteranganValidasi(true);
    // }

    if (statusBarang === "") {
      setStatusBarangValidasi(true);
    }

    // if (statusBarang.value === "Pending") {
    //   if (namaEkspedisi === "") {
    //     setNamaEkspedisiValidasi(true);
    //   }
    // }

    if (dokumen.length === 0) {
      setDokumenValidasi(true);
    }

    if (dokumen.length === 0 || statusBarang === "") {
      notify({
        code: 1,
        message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin untuk membuat surat jalan?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Surat jalan sedang dibuat,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          let formData = new FormData();
          formData.append("idInvoice", idInvoice);
          formData.append("idSo", idSo);
          formData.append("nomorResi", nomorResi);
          formData.append("keterangan", keterangan);
          formData.append("statusSo", statusBarang.value);
          formData.append("namaPenerima", namaPenerima);
          formData.append("alamatPenerima", alamatPenerima);
          formData.append("noTelpPenerima", noHpPenerima);
          formData.append("namaEkspedisi", namaEkspedisi);
          formData.append("alamatEkspedisi", alamatEkspedisi);
          formData.append("telpEkspedisi", telpEkspedisi);

          formData.append(
            "tglSurat",
            moments.tz(new Date(), "Asia/Jakarta").format("YYYY-MM-DD")
          );
          dokumen.map((val, index) => {
            formData.append("dokumen", val);
          });

          tambahSj(formData).then((response) => {
            if (response.data.status === 200) {
              setPending(false);
              setNomorResi("");
              setKeterangan("");
              setNamaEkspedisiValidasi(false);
              setStatusBarang("");
              setStatusBarangValidasi(false);
              setNomorResiValidasi(false);
              setKeteranganValidasi(false);
              setIdInvoice("");
              setIdSo("");
              setNamaEkspedisi("");
              setAlamatEkspedisi("");
              setTelpEkspedisi("");
              setNamaPenerima("");
              setAlamatPenerima("");
              setNoHpPenerima("");
              setDokumen([]);
              setDokumenValidasi(false);
              setModalAddSj(false);
              getInvSj(dispatch, {
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
                tglAwal: moments
                  .tz(new Date(tglAwal), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
                tglAkhir: moments
                  .tz(new Date(tglAkhir), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal menambahkan Produk"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };

  const updateSuratJalan = (e) => {
    e.preventDefault();
    if (statusBarang === "") {
      setStatusBarangValidasi(true);
    }
    if (dokumen.length === 0) {
      setDokumenValidasi(true);
    }
    // if (statusBarang.value === "Pending") {
    //   if (namaEkspedisi === "") {
    //     setNamaEkspedisiValidasi(true);
    //   }
    // }

    if (
      dokumen.length === 0 ||
      statusBarang === ""
      // (statusBarang.value === "Pending" && namaEkspedisi === "")
    ) {
      notify({
        code: 1,
        message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin untuk mengupdate status surat jalan?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Update data sedang dilakukan,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          let formData = new FormData();
          formData.append("idSo", idSo);
          formData.append("idSj", idSj);
          formData.append("statusBarang", statusBarang.value);
          formData.append("namaEkspedisi", namaEkspedisi);
          formData.append("alamatEkspedisi", alamatEkspedisi);
          formData.append("telpEkspedisi", telpEkspedisi);
          formData.append("namaPenerima", namaPenerima);
          formData.append("alamatPenerima", alamatPenerima);
          formData.append("noTelpPenerima", noHpPenerima);
          formData.append("nomorResi", nomorResi);
          formData.append("keterangan", keterangan);
          dokumen.map((val, index) => {
            formData.append("dokumen", val);
          });
          // let data = {
          //   idSo: idSo,
          //   idSj: idSj,
          //   statusBarang: statusBarang.value,
          //   namaEkspedisi: namaEkspedisi,
          //   alamatEkspedisi: alamatEkspedisi,
          //   telpEkspedisi: telpEkspedisi,
          //   namaPenerima: namaPenerima,
          //   alamatPenerima: alamatPenerima,
          //   noTelpPenerima: noHpPenerima,
          //   nomorResi: nomorResi,
          //   keterangan: keterangan,
          // };

          editStatusSj(formData).then((response) => {
            if (response.data.status === 200) {
              setModalEditSj(false);
              setPending(false);
              setNamaEkspedisi("");
              setNamaEkspedisiValidasi(false);
              setStatusBarang("");
              setIdSj("");
              setStatusBarangValidasi(false);
              setIdSo("");
              setAlamatEkspedisi("");
              setTelpEkspedisi("");
              setNamaPenerima("");
              setAlamatPenerima("");
              setNoHpPenerima("");
              setDokumen([]);
              setDokumenValidasi(false);
              getInvSj(dispatch, {
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
                tglAwal: moments
                  .tz(new Date(tglAwal), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
                tglAkhir: moments
                  .tz(new Date(tglAkhir), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal menambahkan Produk"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };

  const notify = (val) => {
    var type = val.code === 0 ? "info" : "danger";

    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b style={{ color: "#000000" }}>{val.message}</b>
          </div>
        </div>
      ),
      type: type,
      icon: val.code === 0 ? "fas fa-box-open" : "fas fa-times-circle",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>Surat Jalan</div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Kode Order/Nama Instansi/Nama Customer"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Col md="4">
          <Row>
            <Col>
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Awal
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(0, 0, 0, 0),
                      setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                  }}
                  value={tglAwal}
                  initialValue={tglAwal}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col>
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Akhir
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(23, 59, 0, 0),
                      setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                  }}
                  value={tglAkhir}
                  initialValue={tglAkhir}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        {/* <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listOrder}
                  //   pagination
                  customStyles={customStyles}
                  paginationPerPage={totalPerHalaman}
                  //   onChangePage={handlePageChange}
                  progressPending={pending}
                />

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label
                            style={{
                              fontWeight: "bold",
                              textTransform: "none",
                              fontSize: 14,
                            }}
                          >
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={orderSj.listInvSj.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        initialPage={page}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Import Produk */}
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Order
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kode Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.idSo}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Instansi
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.customerInstansi}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Customer
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.namaCustomer}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Alamat
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.alamatCustomer}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  No Telpn
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.noTelp}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Email
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder && detailOrder.email === ""
                    ? "-"
                    : detailOrder && detailOrder.email}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder &&
                    moments
                      .tz(new Date(detailOrder.tglOrder), "Asia/Jakarta")
                      .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Selesai Garansi
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder && !detailOrder.selesaiGaransi
                    ? "-"
                    : detailOrder &&
                      moments
                        .tz(
                          new Date(detailOrder.selesaiGaransi),
                          "Asia/Jakarta"
                        )
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Sales
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.sales.namaLengkap}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Status Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder && detailOrder.statusSo === "Ajukan" ? (
                    <b style={{ color: "rgb(255,163,47)" }}>Diorder</b>
                  ) : detailOrder && detailOrder.statusSo === "Proses" ? (
                    <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
                  ) : detailOrder && detailOrder.statusSo === "Tolak" ? (
                    <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
                  ) : detailOrder && detailOrder.statusSo === "Kirim" ? (
                    <b style={{ color: "rgb(68,125,247)" }}>Dikirim</b>
                  ) : detailOrder && detailOrder.statusSo === "Belum" ? (
                    <b style={{ color: "rgb(0,0,0)" }}>Belum Dikirim</b>
                  ) : detailOrder && detailOrder.statusSo === "Pending" ? (
                    <b style={{ color: "rgb(247, 0, 239)" }}>Pending</b>
                  ) : detailOrder && detailOrder.statusSo === "Diambil" ? (
                    <b style={{ color: "rgb(36,204,23)" }}>Diambil</b>
                  ) : detailOrder && detailOrder.statusSo === "Cancel" ? (
                    <b style={{ color: "rgb(251,59,70)" }}>Cancel</b>
                  ) : (
                    <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tipe Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.tipeSo}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Status Pembayaran
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {(detailOrder && !detailOrder.statusPembayarna) ||
                  (detailOrder && detailOrder.statusPembayarna === "") ? (
                    <span>
                      <b>-</b>
                    </span>
                  ) : detailOrder &&
                    detailOrder.statusPembayarna === "Pending" ? (
                    <span style={{ color: "rgb(255,163,47)" }}>
                      <b>Pending</b>
                    </span>
                  ) : detailOrder &&
                    detailOrder.statusPembayarna === "Proses" ? (
                    <span style={{ color: "rgb(68,125,247)" }}>
                      <b>Proses</b>
                    </span>
                  ) : (
                    <span style={{ color: "rgb(36,204,23)" }}>
                      <b>Lunas</b>
                    </span>
                  )}
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Order</b>
              </div>
              <hr></hr>
              <Row>
                {detailOrder &&
                  detailOrder.buktiFoto.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",
                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })}
              </Row>
              <div style={{ marginTop: 20 }}>
                <b>Data Pembelian</b>
              </div>
              <hr></hr>
              <div style={{ overflowX: "auto" }}>
                <table border="1" cellPadding="8" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Kode Produk</th>
                      <th>Nama Produk</th>
                      <th>Tipe Produk</th>
                      <th>Kategori Produk</th>
                      <th>Kapasitas Produk</th>
                      <th>Sumber Produk</th>
                      <th>Tanggal Awal Pengerjaan</th>
                      <th>Tanggal Deadline</th>
                      <th>Tanggal Selesai</th>
                      <th>Status Produk</th>
                      <th>Harga Satuan</th>
                      <th>Qty</th>
                      <th>Selesai</th>
                      <th>Total Harga</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailOrder &&
                      detailOrder.orders.map((item, index) => {
                        return (
                          <tr
                            key={item.produk.kodeProduk}
                            style={{
                              backgroundColor:
                                item.qty != item.qtySelesai
                                  ? "rgba(255, 3, 3,0.3)"
                                  : null,
                            }}
                          >
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>{item.produk.kodeProduk}</td>
                            <td>{item.produk.namaProduk}</td>
                            <td>{item.produk.tipe}</td>
                            <td>
                              {item.produk.kategoriProduk === "upgrade"
                                ? "UPGRADE"
                                : "UTAMA"}
                            </td>
                            <td>{item.produk.kapasitas}</td>
                            <td>
                              {item.sumberProduk === "PL"
                                ? "Pesan Langsung"
                                : "Gudang"}
                            </td>
                            <td>
                              {!item.tglAwalPengerjaan ||
                              item.tglAwalPengerjaan === ""
                                ? "-"
                                : moments
                                    .tz(
                                      new Date(item.tglAwalPengerjaan),
                                      "Asia/Jakarta"
                                    )
                                    .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {!item.tglDeadline || item.tglDeadline === ""
                                ? "-"
                                : moments
                                    .tz(
                                      new Date(item.tglDeadline),
                                      "Asia/Jakarta"
                                    )
                                    .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {!item.tglSelesai || item.tglSelesai === ""
                                ? "-"
                                : moments
                                    .tz(
                                      new Date(item.tglSelesai),
                                      "Asia/Jakarta"
                                    )
                                    .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {item.statusProduk === "" || !item.statusProduk
                                ? "-"
                                : item.statusProduk}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.produk.hargaProduk)}
                            </td>
                            <td style={{ textAlign: "center" }}>{item.qty}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.qtySelesai}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.totalHarga)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="14">
                        <b>Total Pembelian</b>
                      </td>
                      <td>
                        <b>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(detailOrder && detailOrder.totalPembelian)}
                        </b>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div style={{ marginTop: 20 }}>
                <b>Data Invoice</b>
              </div>
              <hr></hr>
              <div style={{ overflowX: "auto" }}>
                <table border="1" cellPadding="8" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>No Invoice</th>
                      <th>Tgl Invoice</th>
                      <th>Tipe Pembayaran</th>
                      <th>Nama Admin</th>
                      <th>Total Pembelian</th>
                      <th>Nominal Admin</th>
                      <th>Nominal Peti</th>
                      <th>Nominal Ongkir</th>
                      <th>Nominal Diskon</th>
                      <th>Nominal Bayar</th>
                      <th>Nominal DP</th>
                      <th>Nominal Lunas</th>
                      <th>Nominal Sisa Bayar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailOrder && detailOrder.invoices.length === 0 ? (
                      <tr>
                        <td colSpan="14">
                          <span style={{ color: "grey" }}>
                            Belum memiliki data invoice
                          </span>
                        </td>
                      </tr>
                    ) : (
                      detailOrder &&
                      detailOrder.invoices.map((item, index) => {
                        return (
                          <tr key={item.idInvoice}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>{item.idInvoice}</td>
                            <td>
                              {moments
                                .tz(new Date(item.tglInvoice), "Asia/Jakarta")
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{item.tipePembayaran}</td>
                            <td>{item.user.namaLengkap}</td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.totalPembelian)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalAdmin)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalPeti)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalOngkir)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalDiskon)}
                            </td>
                            <td>
                              {index === 0
                                ? new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(
                                    item.totalPembelian +
                                      item.nominalAdmin +
                                      item.nominalPeti +
                                      item.nominalOngkir -
                                      item.nominalDiskon
                                  )
                                : item.tipePembayaran === "DP"
                                ? new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalDp)
                                : new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalLunas)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalDp)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalLunas)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalSisaBayar)}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>

              <div style={{ marginTop: 20 }}>
                <b>Data Surat Jalan</b>
              </div>
              <hr></hr>
              <div style={{ overflowX: "auto" }}>
                <table border="1" cellPadding="8" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Kode Surat Jalan</th>
                      <th>Nomor Resi</th>
                      <th>Nama Admin</th>
                      <th>Tanggal Surat</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailOrder && detailOrder.suratJalan.length === 0 ? (
                      <tr>
                        <td colSpan="14">
                          <span style={{ color: "grey" }}>
                            Belum memiliki data surat jalan
                          </span>
                        </td>
                      </tr>
                    ) : (
                      detailOrder &&
                      detailOrder.suratJalan.map((item, index) => {
                        return (
                          <tr key={item.idSuratJalan}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>{item.idSuratJalan}</td>
                            <td>{item.nomorResi}</td>
                            <td>{item.user.namaLengkap}</td>
                            <td>
                              {moments
                                .tz(new Date(item.tglSurat), "Asia/Jakarta")
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{item.keterangan}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Surat Jalan</b>
              </div>
              <hr></hr>
              <Row>
                {detailOrder && detailOrder.suratJalan.length === 0 ? (
                  <span style={{ color: "grey", marginLeft: 20 }}>
                    Belum memiliki dokumen surat jalan.
                  </span>
                ) : (
                  detailOrder &&
                  detailOrder.suratJalan[0].buktiFoto.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",

                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      {/* Modal Add Surat Jalan */}
      <Modal
        size="md"
        show={modalAddSj}
        onHide={() => {
          setModalAddSj(false);
          setPending(false);
          setNomorResi("");
          setKeterangan("");
          setNamaEkspedisi("");
          setNamaEkspedisiValidasi(false);
          setStatusBarang("");
          setStatusBarangValidasi(false);
          setNomorResiValidasi(false);
          setKeteranganValidasi(false);
          setIdInvoice("");
          setIdSo("");
          setDokumen([]);
          setDokumenValidasi(false);
          setAlamatEkspedisi("");
          setTelpEkspedisi("");
          setNomorResi("");
          setKeterangan("");
          setNamaPenerima("");
          setAlamatPenerima("");
          setNoHpPenerima("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah Surat Jalan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kode Sales Order<span className="star">*</span>
                  </label>
                  <Form.Control
                    disabled
                    // onChange={(e) => {
                    //   setNomorResi(e.target.value);
                    //   setNomorResiValidasi(false);
                    // }}
                    placeholder="Kode Sales Order"
                    type="text"
                    value={idSo}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kode Invoice<span className="star">*</span>
                  </label>
                  <Form.Control
                    disabled
                    // onChange={(e) => {
                    //   setNomorResi(e.target.value);
                    //   setNomorResiValidasi(false);
                    // }}
                    placeholder="Kode Invoice"
                    type="text"
                    value={idInvoice}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nomor Resi
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNomorResi(e.target.value);
                      // setNomorResiValidasi(false);
                    }}
                    placeholder="Nomor Resi"
                    type="text"
                    value={nomorResi}
                  ></Form.Control>
                  {/* {nomorResiValidasi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Nomor resi tidak boleh kosong.
                    </label>
                  )} */}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Keterangan
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setKeterangan(e.target.value);
                      // setKeteranganValidasi(false);
                    }}
                    placeholder="Keterangan"
                    type="text"
                    value={keterangan}
                  ></Form.Control>
                  {/* {keteranganValidasi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Keterangan tidak boleh kosong.
                    </label>
                  )} */}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Penerima
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNamaPenerima(e.target.value);
                    }}
                    placeholder="Nama Penerima"
                    type="text"
                    value={namaPenerima}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Alamat Penerima
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setAlamatPenerima(e.target.value);
                    }}
                    placeholder="Alamat Penerima"
                    type="text"
                    value={alamatPenerima}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    No Telp Penerima
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNoHpPenerima(e.target.value);
                    }}
                    placeholder="No Telp Penerima"
                    type="text"
                    value={noHpPenerima}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Status Barang <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={statusBarang}
                    onChange={(value) => {
                      setStatusBarang(value);
                      setStatusBarangValidasi(false);
                      setNamaEkspedisiValidasi(false);

                      if (value.value != "Pending") {
                        setNamaEkspedisi("");
                      }
                    }}
                    options={[
                      { value: "Belum", label: "Belum Dikirim" },
                      { value: "Pending", label: "Pending" },
                      { value: "Kirim", label: "Dikirim" },
                      { value: "Diambil", label: "Diambil" },
                    ]}
                    placeholder="Pilih Status Barang"
                  />
                  {statusBarangValidasi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Status barang tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                {statusBarang.value === "Pending" ||
                statusBarang.value === "Kirim" ||
                statusBarang.value === "Diambil" ? (
                  <>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nama Ekspedisi
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaEkspedisi(e.target.value);
                          // setNamaEkspedisiValidasi(false);
                        }}
                        placeholder="Nama Ekspedisi"
                        type="text"
                        value={namaEkspedisi}
                      ></Form.Control>
                      {/* {namaEkspedisiValidasi === false ? null : (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Nomor ekspedisi tidak boleh kosong.
                      </label>
                    )} */}
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Alamat Ekspedisi
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAlamatEkspedisi(e.target.value);
                          // setNamaEkspedisiValidasi(false);
                        }}
                        placeholder="Alamat Ekspedisi"
                        type="text"
                        value={alamatEkspedisi}
                      ></Form.Control>
                      {/* {namaEkspedisiValidasi === false ? null : (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Nomor ekspedisi tidak boleh kosong.
                      </label>
                    )} */}
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        No Telp Ekspedisi
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setTelpEkspedisi(e.target.value);
                          // setNamaEkspedisiValidasi(false);
                        }}
                        placeholder="Telp Ekspedisi"
                        type="text"
                        value={telpEkspedisi}
                      ></Form.Control>
                      {/* {namaEkspedisiValidasi === false ? null : (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Nomor ekspedisi tidak boleh kosong.
                      </label>
                    )} */}
                    </Form.Group>
                  </>
                ) : null}

                <div>
                  <div>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Upload Dokumen{" "}
                      <span className="star" style={{ textTransform: "none" }}>
                        *{" "}
                        <span style={{ color: "blue" }}>
                          (Maksimal 2 Dokumen)
                        </span>
                      </span>
                    </label>
                  </div>

                  <label
                    className="label-input-file btn text-white mb-4"
                    htmlFor="import2"
                    style={{
                      fontWeight: "bold",
                      backgroundColor:
                        dokumen.length === 2 ? "grey" : "#447DF7",
                      border: 0,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      <i
                        className="fas fa-images"
                        style={{ marginRight: 5 }}
                      ></i>
                      Pilih File
                    </div>

                    <input
                      disabled={pending || dokumen.length === 2 ? true : false}
                      type="file"
                      id="import2"
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      accept=".jpg,.jpeg,.png"
                      multiple
                    />
                  </label>
                  {dokumenValidasi === false ? null : (
                    <label
                      className="error"
                      style={{
                        textTransform: "none",
                        color: "red",
                        marginLeft: 10,
                      }}
                    >
                      Dokumen tidak boleh kosong.
                    </label>
                  )}
                  {dokumen.length === 0 ? (
                    <div>
                      <p style={{ color: "GrayText" }}>File kosong</p>
                    </div>
                  ) : (
                    dokumen.map((file, index) => (
                      <div className="d-flex align-items-center" key={index}>
                        <p className="mb-1 mr-1">{file.name}</p>

                        <i
                          className="fa fa-times"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: 10,
                          }}
                          onClick={() =>
                            pending ? null : removeDokumen(index)
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={buatSuratJalan}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Edit Surat Jalan */}
      <Modal
        size="md"
        show={modalEditSj}
        onHide={() => {
          setModalEditSj(false);
          setPending(false);
          setNamaEkspedisi("");
          setNamaEkspedisiValidasi(false);
          setStatusBarang("");
          setIdSj("");
          setStatusBarangValidasi(false);
          setIdSo("");
          setAlamatEkspedisi("");
          setTelpEkspedisi("");
          setNomorResi("");
          setKeterangan("");
          setNamaPenerima("");
          setAlamatPenerima("");
          setNoHpPenerima("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Edit Status Surat Jalan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nomor Resi
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNomorResi(e.target.value);
                      // setNomorResiValidasi(false);
                    }}
                    placeholder="Nomor Resi"
                    type="text"
                    value={nomorResi}
                  ></Form.Control>
                  {/* {nomorResiValidasi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Nomor resi tidak boleh kosong.
                    </label>
                  )} */}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Keterangan
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setKeterangan(e.target.value);
                      // setKeteranganValidasi(false);
                    }}
                    placeholder="Keterangan"
                    type="text"
                    value={keterangan}
                  ></Form.Control>
                  {/* {keteranganValidasi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Keterangan tidak boleh kosong.
                    </label>
                  )} */}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Status Barang <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={statusBarang}
                    onChange={(value) => {
                      setStatusBarang(value);
                      setStatusBarangValidasi(false);
                      // setNamaEkspedisiValidasi(false);

                      // if (value.value != "Pending") {
                      //   setNamaEkspedisi("");
                      // }
                    }}
                    options={[
                      { value: "Belum", label: "Belum Dikirim" },
                      { value: "Pending", label: "Pending" },
                      { value: "Kirim", label: "Dikirim" },
                      { value: "Diambil", label: "Diambil" },
                    ]}
                    placeholder="Pilih Status Barang"
                  />
                  {statusBarangValidasi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Status barang tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                {statusBarang.value === "Pending" ||
                statusBarang.value === "Kirim" ||
                statusBarang.value === "Diambil" ? (
                  <>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nama Penerima
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaPenerima(e.target.value);
                        }}
                        placeholder="Nama Penerima"
                        type="text"
                        value={namaPenerima}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Alamat Penerima
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAlamatPenerima(e.target.value);
                        }}
                        placeholder="Alamat Penerima"
                        type="text"
                        value={alamatPenerima}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        No Telp Penerima
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNoHpPenerima(e.target.value);
                        }}
                        placeholder="No Telp Penerima"
                        type="text"
                        value={noHpPenerima}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Nama Ekspedisi
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setNamaEkspedisi(e.target.value);
                          // setNamaEkspedisiValidasi(false);
                        }}
                        placeholder="Nama Ekspedisi"
                        type="text"
                        value={namaEkspedisi}
                      ></Form.Control>
                      {/* {namaEkspedisiValidasi === false ? null : (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Nomor ekspedisi tidak boleh kosong.
                      </label>
                    )} */}
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Alamat Ekspedisi
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setAlamatEkspedisi(e.target.value);
                          // setNamaEkspedisiValidasi(false);
                        }}
                        placeholder="Alamat Ekspedisi"
                        type="text"
                        value={alamatEkspedisi}
                      ></Form.Control>
                      {/* {namaEkspedisiValidasi === false ? null : (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Nomor ekspedisi tidak boleh kosong.
                      </label>
                    )} */}
                    </Form.Group>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        No Telp Ekspedisi
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setTelpEkspedisi(e.target.value);
                          // setNamaEkspedisiValidasi(false);
                        }}
                        placeholder="Telp Ekspedisi"
                        type="text"
                        value={telpEkspedisi}
                      ></Form.Control>
                      {/* {namaEkspedisiValidasi === false ? null : (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Nomor ekspedisi tidak boleh kosong.
                      </label>
                    )} */}
                    </Form.Group>
                  </>
                ) : null}
                <div>
                  <div>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Upload Dokumen{" "}
                      <span className="star" style={{ textTransform: "none" }}>
                        *{" "}
                        <span style={{ color: "blue" }}>
                          (Maksimal 2 Dokumen)
                        </span>
                      </span>
                    </label>
                  </div>

                  <label
                    className="label-input-file btn text-white mb-4"
                    htmlFor="import2"
                    style={{
                      fontWeight: "bold",
                      backgroundColor:
                        dokumen.length === 2 ? "grey" : "#447DF7",
                      border: 0,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      <i
                        className="fas fa-images"
                        style={{ marginRight: 5 }}
                      ></i>
                      Pilih File
                    </div>

                    <input
                      disabled={pending || dokumen.length === 2 ? true : false}
                      type="file"
                      id="import2"
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      accept=".jpg,.jpeg,.png"
                      multiple
                    />
                  </label>
                  {dokumenValidasi === false ? null : (
                    <label
                      className="error"
                      style={{
                        textTransform: "none",
                        color: "red",
                        marginLeft: 10,
                      }}
                    >
                      Dokumen tidak boleh kosong.
                    </label>
                  )}
                  {dokumen.length === 0 ? (
                    <div>
                      <p style={{ color: "GrayText" }}>File kosong</p>
                    </div>
                  ) : (
                    dokumen.map((file, index) => (
                      <div className="d-flex align-items-center" key={index}>
                        <p className="mb-1 mr-1">{file.name}</p>

                        <i
                          className="fa fa-times"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: 10,
                          }}
                          onClick={() =>
                            pending ? null : removeDokumen(index)
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={updateSuratJalan}
              >
                Update
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal show image */}
      <Modal
        size="lg"
        show={modalImage}
        onHide={() => {
          setModalImage(false);
          setUrlImage("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Gambar
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Image
                src={urlImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
                thumbnail
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default SuratJalan;
